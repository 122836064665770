<template>
  <svg 
    id="logoOrisis" 
    data-name="Logo_ORISIS" 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 63.44 63"
    style="cursor: pointer !important;"
  >
    <title>Logo ORISIS</title>
    
    <path d="M31.72,24.5a7.08,7.08,0,1,1-7.09,7.08,7.08,7.08,0,0,1,7.09-7.08Z"/>
    <path d="M31.92,63a3.46,3.46,0,0,1-2.43-1,3.35,3.35,0,0,1-1-2.39,3.41,3.41,0,0,1,3.36-3.45A24.7,24.7,0,0,0,56.3,31.2v-.05A24.83,24.83,0,0,0,31.64,6.78h-.19A24.74,24.74,0,0,0,7,31.77a25,25,0,0,0,.4,4.14v.2a3.39,3.39,0,0,1-2.74,4,4,4,0,0,1-.6,0H3.89A3.42,3.42,0,0,1,.55,37.39,31.54,31.54,0,0,1,25.65.56a32.22,32.22,0,0,1,6-.56h.18a31.5,31.5,0,0,1,.32,63h-.18Z"/>
    <path d="M31.34,50.63a18.9,18.9,0,0,1-8.66-35.7A3.22,3.22,0,0,1,27,16.3a3.17,3.17,0,0,1-1.37,4.27,12.57,12.57,0,0,0-5.39,16.94,12.72,12.72,0,0,0,2.33,3.17A12.58,12.58,0,0,0,43.86,30.3a3.18,3.18,0,0,1,2.8-3.51H47a3.14,3.14,0,0,1,2,.69,3.17,3.17,0,0,1,1.18,2.13A19,19,0,0,1,33.5,50.51,19.28,19.28,0,0,1,31.34,50.63Z"/>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      color: null,
    };
  },
};
</script>

<style scoped>
#logoOrisis{
  transition: transform 1s ease-in-out;
}
#logoOrisis:hover {
  transform: rotateZ(360deg);
}
#logoOrisis{
  fill:#0c3571;
  fill-rule:evenodd;
}
</style>